<template>
    <div class="bg_c">
        <div class="home" id="home">
            <MyHeader />
            <div class="main mains">
                <div class="text">
                    <div class="left">
                        <p><span>LOKI </span>FINANCE IS BUILDING</p>
                        <p class="NoneShaDow" style="color: rgba(255, 255, 255, .5);">MEMEFI FOR MEME TOKENS.</p>
                        <p>Trust CODE.</p>
                        <p>Trust<span class="us"> humanity </span>LOKI</p>
                        <p class="sitext NoneShaDow">LOKI  is the ultimate shit coin and meme token that helps bring liquidity
                            and
                            DeFi to all meme tokens.</p>
                        <ul class="ico_list">
                            <li v-for="(item, index) in  ico_list " :key="index">
                                <a :href="item.href" :style="item.style"></a>
                            </li>
                        </ul>
                        <div class="btns">
                            <a
                                href="https://github.com/AnalytixAudit/Solidity/blob/main/20230714_AnalytixAudit_X%20AI_X_Audit.pdf">
                                <div class="btn_item audit">
                                    Audit
                                </div>
                            </a>
                            <a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xe8fFA3730e8d8CC177BEAfcb57f247F833e9bd47">
                                <div class="btn_item audit">
                                   BUY
                                </div>
                            </a>
                            <a href="">
                                <div class="btn_item audit">
                                    Whitepaper
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <!-- <img src="../assets/images/homeRight.png" alt=""> -->
                </div>

            </div>
            <!-- <MyBanner class="MyBanner" :speeds="3000" :ss="3" /> -->

        </div>
    </div>

    <div class="containers">
        <About />
        <MyHow />
        <MyToken />
        <MyPoamap />
        <MyContact />
        <!-- <Footer /> -->
    </div>
</template>
  
<script>
import MyHeader from '../components/MyHeader.vue';
import About from '../components/About.vue';
import MyHow from '../components/MyHow.vue';
import MyToken from '../components/MyToken.vue';
import MyPoamap from '../components/MyPoamap.vue';
import MyContact from '../components/MyContact.vue';
import Footer from '../components/Footer.vue';
import MyBanner from '../components/banner_an.vue';
export default {
    data() {
        return {
            // 首页图标链接
            ico_list: [{
                href: 'https://twitter.com/RealLokiArmy'
            }, {
                href: 'https://t.me/LokiEntire'
            }, {
                href: 'https://www.dextools.io/app/cn/bnb/pair-explorer/0x16f7209b4f91984b52c1a28074b978c81b0d26ca?t=1712709790762'
            }, {
                href: 'https://www.dexview.com/bsc/0xe8fFA3730e8d8CC177BEAfcb57f247F833e9bd47'
            }, {
                href: '#'
            }, {
                href: 'https://www.pinksale.finance/'
            }]
        }
    },
    created() {
        this.iconlist()
    },
    methods: {
        iconlist() {
            const icoarr = []
            this.ico_list.forEach((item, index) => {
                item.style = `background-image: url(../img/00${index + 1}.png)`
                icoarr.push(item)
            });
            this.ico_list = icoarr
        }
    },
    components: {
        MyHeader, About, MyHow, MyToken, MyPoamap, MyContact, Footer, MyBanner
    }
}
</script>
  
<style lang="less" scoped>
.MyBanner {
    width: 100%;
    position: absolute;
    top: 100%;
    transform: translateY(-120%);
}

.banner_1 {
    margin-top: 100px;
}

.bg_c {
    background: #141514;
}

.sitext {
    font-size: 16px !important;
    color: rgba(255, 255, 255, .7) !important;
    opacity: 0.5;
    padding-top: 15px;
    margin: 25px 0;
    font-family: 'Inter' !important;
}

.btns {
    display: flex;
    width: 100%;
    margin-top: 15px;

    a {
        margin-right: 10px;
        font-family: 'roboto' !important;
    }

    .btn_item {
        // border: 3px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        width: 230px;

        img {
            width: 100%;
        }

        .btn_t {
            text-shadow: 1px 1px 10px #7f7f7f;
            margin-top: 0 !important;
            font-size: 18px !important;
        }
    }

    .launch {

        background: conic-gradient(from 0deg at 50% 57.32%, #6CAB37 0deg, #418141 360deg);
        /* Launch */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;

        display: flex;
        align-items: center;
        line-height: 19px;

        border-radius: 8px;
        color: #FFFFFF;
        padding: 15px 20px;
    }

    .audit {
        box-sizing: border-box;
        width: auto;
        /* Auto layout */
        padding: 15px 45px;
        border-radius: 5px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        border: 1px solid #fff;

        p {
            color: #fff !important;
            text-shadow: none !important;
        }
    }

    .Whitepaper {
        border: 1px solid #000;
        border-radius: 5px;
        color: #fff;
        height: 100%;
        background-color: #000;
    }
}

.containers {
    width: 100%;
    // background-image: url('../assets/images/aboutbg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    // background: url('../assets/images/pageBg.png');
    background-size: 100% auto;
    background-color: #141514;
}

.home {
    position: relative;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    background: url('../assets/images/bgs.png') no-repeat;
    background-size: cover;
    background-position: top left;

    .mains {
        display: flex;
        max-width: 1400px;
        // margin-top: 1%;
        overflow: hidden;
    }


    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 5%;
        bottom: 10%;
        width: 50%;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .text {
        display: flex;
        align-items: center;
        height: 869px;

        .left {
            z-index: 9;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 75%;

            .texts {
                position: relative;

                p:nth-child(2) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform: translateY(-3px);
                    z-index: 1;
                    background: linear-gradient(to right, #e9bd4c, #fff, #e9bd4c);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .title {
                margin-bottom: 13px;
            }


            p {
                font-family: 'exo';
                width: 100%;
                text-align: left;
                font-size: 50px;
                color: rgba(255, 255, 255, .9);
                margin-top: 18px;
                text-transform: uppercase;
                text-shadow: 4px 4px 0 rgba(0, 0, 0, .8);
                &.NoneShaDow {
                    text-shadow: none;
                }

                span {
                    font-family: 'exo';
                }

                .us {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 90%;
                        height: 4px;
                        left: 50%;
                        top: 56%;
                        transform: translateX(-50%);
                        background-color: #ff3b3b;
                    }
                }
            }

            .ico_list {
                margin-top: 0px;
                display: flex;
                width: 100%;
                align-items: center;
                flex-wrap: wrap;
                z-index: 2;

                li {
                    margin-right: 12px;
                    margin: 10px;

                    a {
                        display: block;
                        width: 80px;
                        height: 80px;
                        background-size: cover;
                    }
                }
            }
        }

    }

}

@media screen and (max-width:1350px) {
    .home .text .right img {
        width: 100%;
    }
}

@media screen and (max-width:1200px) {
    .container {
        padding-top: 200px;
    }
}

@media screen and (max-width:1000px) {
    .container {
        padding-top: 300px;
    }
}

@media screen and (max-width:1120px) {
    .home .mains {
        padding-left: 10px;
    }

    .home .right {
        position: absolute;
        left: 50%;
        // top: 0;
        bottom: 52%
    }

    .home .text .left {
        width: 100%;
        align-items: center;
    }
}

@media screen and (max-width:1000px) {
    .right {
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
    }

    .home .text {
        flex-direction: column-reverse;
        height: auto;
        margin-bottom: 80px;

        .left {
            align-items: center;
            padding-top: 300px;
        }
    }
}

@media screen and (max-width:700px) {
    .home .text .right {
        width: 100%;
    }


    .btn_item {
        width: 100% !important;
    }

    .MyBanner {
        display: none;
    }
}

@media screen and (max-width:500px) {
    .home {
        .bottom {
            margin-top: 40px;

            img {
                width: 280%;
            }
        }

        .text {
            .left {
                padding-top: 150px;
            }

            .btns {
                flex-direction: column;

                a {
                    margin: 5px 0 !important;
                }
            }
        }
    }

    .home .right {
        width: 200px;
    }

    .home .text .left p {
        font-size: 40px;
    }

    .home .right img {
        transform: translateY(-30%);
    }
}
</style>
  